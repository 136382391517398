(function () {
  'use strict';

  var functionNames = {
    'Array': [
      'concat',
      // 'copyWithin',
      // 'entries',
      // 'every',
      // 'fill',
      'filter',
      // 'find',
      // 'findIndex',
      'forEach',
      // 'includes',
      // 'indexOf',
      // 'join',
      // 'keys',
      // 'lastIndexOf',
      'map',
      'pop',
      'push',
      'reduce'
      // 'reduceRight',
      // 'reverse',
      // 'shift',
      // 'slice',
      // 'some',
      // 'sort',
      // 'splice',
      // 'toLocaleString',
      // 'toSource',
      // 'toString',
      // 'unshift',
      // 'values'
    ]
  };

  window.savedFunctions = {};

  for (var standardObjectName in functionNames) {
    if ({}.hasOwnProperty.call(functionNames, standardObjectName)) {
      var currentObjectFunctions = functionNames[standardObjectName];

      window.savedFunctions[standardObjectName] = [];

      for (var i = 0, length = currentObjectFunctions.length; i < length; i += 1) {
        var currentFunctionName = currentObjectFunctions[i];

        window.savedFunctions[standardObjectName]
        .push({
          functionName: currentFunctionName,
          functionCode: window[standardObjectName].prototype[currentFunctionName]
        });
      }
    }
  }
}());